import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import MDLayout from '../components/md_layout'
import SEO from '../components/md_seo'

interface Props {
  data: any
}

const Template: React.FC<Props> = ({
  data // this prop will be injected by the GraphQL query below.
}) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout header>
      <SEO postData={data} />
      <MDLayout>
        <article>
          <header>
            <h1 className="text-3xl leading-9 font-extrabold tracking-tight text-blue-900 sm:text-4xl sm:leading-10 my-10">
              {frontmatter.title}
            </h1>
          </header>
          <section className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
          <hr className="h-px mb-8" />
        </article>
      </MDLayout>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`

export default Template
