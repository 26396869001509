import React from 'react'

interface Props {
  readonly children?: React.ReactNode | readonly React.ReactNode[]
}

const MDLayout: React.FC = ({ children }: Props) => {
  return <div className="container mx-auto my-10 l:pl-20 l:pr-20">{children}</div>
}

export default MDLayout
